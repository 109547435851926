import './App.css';

function App() {
const queryParameters = new URLSearchParams(window.location.search)
  const vid = queryParameters.get("vid")

  if(vid==="berlin"){
    return (
      <div className="videoContainer berlinVideoContainer">
        <video className="berlinVideo" playsInline autoPlay muted loop>
          <source src="Berlin-Tempelhof_V2.mp4" type="video/mp4"/>
        Your browser does not support the video tag.
        </video>
      </div>
    );
  } else {
    return (
      <div className="videoContainer">
        <video playsInline autoPlay muted loop>
          <source src="FormelE_AI-Filter_V2.mp4" type="video/mp4"/>
        Your browser does not support the video tag.
        </video>
      </div>
    );
  }

}

export default App;
